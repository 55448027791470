$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache  : false
    });

    /*
     * Set mobile Safari in standalone
     */
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes)) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);
    }

    /*
     * Isotope
     */
//    var $grid = $('.grid').isotope({});
//    // filter items on button click
//    $('.filter-button-group').on('click', 'button', function () {
//        var filterValue = $(this).attr('data-filter');
//        $grid.isotope({filter: filterValue});
//    });
//    // Apply Isotope after images loaded
//    $('.grid').imagesLoaded(function () {
//        $('.grid').isotope({
//            itemSelector: '.grid-item',
//        });
//        //$('.grid-item').animate({opacity: 1.0}, 200);
//    });

    /*
     * Smooth Scroll
     */
    $('.scroll').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target     = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    /*
     * Apply Affix
     */
    $('.navbar').affix({
        offset: {
            top: 40
        }
    })

    /*
     * Flash messages
     */
    $('#flash-overlay-modal').modal();
    /*
     * Flash messages auto clear
     */
    $('.modal-auto-clear').on('shown.bs.modal', function () {
        // if data-timer attribute is set use that, otherwise use default (7000)
        var timer = $(this).data('timer') ? $(this).data('timer') : 9000;
        $(this).delay(timer).fadeOut(200, function () {
            $(this).modal('hide');
        });
    })
    /*
     * Initiate colorbox
     */
    // $('a.gallery').colorbox({
    //     rel           : 'gal',
    //     slideshow     : true,
    //     slideshowSpeed: 5000,
    //     maxHeight     : '90%',
    //     maxWidth      : '90%'
    // });

    /*
     * Initiate Swiper
     */
    var mySwiper = new Swiper('.swiper-container', {
        autoplay: 7000,
        speed   : 600,
        loop    : true,
        parallax: true,
        effect  : 'fade',

        // If we need pagination
        pagination         : '.swiper-pagination',
        paginationClickable: true,

        //// Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        //
        //// And if we need scrollbar
        //scrollbar: '.swiper-scrollbar',
    })



    /*
     * Initiate popovers and tooltips
     */
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip({html: true});


    $(".ellipsis").dotdotdot({

        watch: "window",
        /*	The text to add as ellipsis. */
        ellipsis        : '... ',
        /*	How to cut off the text/html: 'word'/'letter'/'children' */
        wrap            : 'word',
        /*	Wrap-option fallback to 'letter' for long words */
        fallbackToLetter: true,
        /*	jQuery-selector for the element to keep and put after the ellipsis. */
        after           : null,
        /*	Whether to update the ellipsis: true/'window' */
        watch           : false,
        /*	Optionally set a max-height, can be a number or function.
         If null, the height will be measured. */
        height          : null,
        /*	Deviation for the height-option. */
        tolerance       : 0,
        /*	Callback function that is fired after the ellipsis is added,
         receives two parameters: isTruncated(boolean), orgContent(string). */
        callback        : function (isTruncated, orgContent) {
        },
        lastCharacter   : {
            /*	Remove these characters from the end of the truncated text. */
            remove    : [' ', ',', ';', '.', '!', '?'],
            /*	Don't add an ellipsis if this array contains
             the last character of the truncated text. */
            noEllipsis: []
        }
    });

    /*
     * Apply Scrollreveal
     */
//    window.sr = ScrollReveal({
//        origin: 'bottom',
//        delay: 100,
//        viewFactor: 0.3,
//        mobile: true,
//    });
//    sr.reveal('.reveal');

    window.sr = ScrollReveal({
        distance: '10px',
        useDelay: 'once',
        mobile  : false,
        vFactor : 0.50,
        duration: 100,
        delay   : 500,
        distance: '10px',
    });

    sr.reveal('.reveal');


    $('.content').imagesLoaded()
        .done(function (instance) {
            sr.reveal('.reveal', {
                origin: 'bottom',
            });
            sr.reveal('.reveal-rotate', {
                rotate: {x: 0, y: 0, z: 30},
            });
            log('all images successfully loaded');
        })
        .fail(function () {
            log('all images loaded, at least one is broken');
        })
        .progress(function (instance, image) {
            var result = image.isLoaded ? 'loaded' : 'broken';
            log('image is ' + result + ' for ' + image.img.src);
        });
    /*
      * Apply Matchheight
      */
    $('.matchheight').matchHeight();



    /*
     * Change phone prefix
     */
    $('#country').on('change', function () {

        // console.log($(this).val());
        var country = $(this).val().toUpperCase();
        var loadUrl = '/async/districts';
        // var select =
        $.ajax({
            url     : loadUrl,
            type    : "POST",
            cache   : false,
            data    : {'country': country},
            dataType: 'JSON',

            success: function (data) {

                if (jQuery.isEmptyObject(data)) {
                    $('#group-district').hide();
                }
                else {

                    var listitems;

                    $.each(data, function (key, value) {
                        listitems += '<option value=' + value.name + '>' + value.name + '</option>';
                    });

                    $('#district').empty().append(listitems);

                    $('#group-district').show();

                }

            },

            error: function (data) {
            }

        });

        // Set phone prefix
        console.log($(this).children('option:selected').data('prefix'));
        $('#prefix').val('+' + $(this).children('option:selected').data('prefix'));
        $('#prefixTitle').html($('#prefix').val());
    });

    $('#prefix').val('+' + $('#country').children('option:selected').data('prefix'));
    $('#prefixTitle').html($('#prefix').val());

    function log(msg) {
        try {
            console.log(msg);
        } catch (e) {
        }
    }

});